import { CContainer, CRow } from "@coreui/react";
// import { CCol } from "@coreui/react"
import React from "react";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@mui/lab";

const About = () => {
  const primary_color = "#2979ff";
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();
  const month_word = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ][month];

  const years_experience = year - 2017;

  const timeline_data = [
    "May 2017; Graduate with B.S. in Chemical Engineering, Minors in Mathematics, Chemistry, Computer Science.  University of Tulsa",
    "June 2017; New Job as Chemical Process Engineer. Phillips 66",
    "Jan 2018; Start M.S. Degree in Computer Science. Georgia Institute of Technology",
    "Aug 2018; Start Job as Machine Learning Engineer / Advanced Process Control Engineer. Phillips 66",
    "May 2020; Graduate with M.S. in Computer Science - Specialization in Machine Learning. Georgia Institute of Technology",
    "April 2022; Start Job as Principle Software Developer. Armexa",
    "October 2022; Married!",
    "September 2024; Start Job as Sr. Machine Learning Engineer.  The University of Texas MD Anderson Cancer Center",
  ];
  // month_word + ' ' + date.getFullYear() + '; Today']
  const timeline_code = timeline_data.map((element) => (
    // eslint-disable-next-line react/jsx-key
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>{element}</TimelineContent>
    </TimelineItem>
  ));
  const timeline_today = (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot />
        {/* <TimelineConnector /> */}
      </TimelineSeparator>
      <TimelineContent>
        {month_word} {date.getFullYear()}; Today
      </TimelineContent>
    </TimelineItem>
  );

  return (
    <div style={{ textAlign: "center", margin: "0 auto" }}>
      <CContainer style={{ padding: 24 }}>
        {/* <pre style={{backgroundColor:'lightgrey', fontSize:28, display:'inline-block'}}>
                    $ WHOIS Brent Marcoux
                </pre> */}
        <h1 style={{ paddingBottom: 24 }}>
          <span style={{ color: primary_color }}>$ WHOIS</span> Brent Marcoux
        </h1>
        {/* <CRow>
                    <CCol>
                        <h5 style={{textAlign:'left'}}>
                            I am an enthusiastic <span style={{color:primary_color}}>lifelong learner</span> with values in <span style={{color:primary_color}}>honor</span> and <span style={{color:primary_color}}>respect</span>.<br></br>
                            Along with {years_experience.toLocaleString()} years of industry experience, <br></br>
                            I hold a B.S. in Chemical Engineering and a  M.S. in Computer Science with a specialization in machine learning
                        </h5>
                    </CCol>
                    <CCol>
                        <Timeline style={{alignContent:'left'}}>
                            {timeline_code}
                        </Timeline>
                    </CCol> */}

        <CRow>
          {/* <div className="clearfix"> */}
          <div>
            {/* <Timeline className="col-md-6 float-md-end mb-3 ms-md-3"style={{alignContent:'left'}}>
                                {timeline_code}
                            </Timeline> */}
            <h5 style={{ textAlign: "left", paddingBottom: 32 }}>
              I am an enthusiastic{" "}
              <span style={{ color: primary_color }}>lifelong learner</span>{" "}
              with values in <span style={{ color: primary_color }}>honor</span>{" "}
              and <span style={{ color: primary_color }}>respect</span>.
              <br></br>
              <br></br>
              Along with{" "}
              <span style={{ color: primary_color }}>
                {years_experience.toLocaleString()}
              </span>{" "}
              years of industry experience, <br></br>
              <br></br>I hold a{" "}
              <span style={{ color: primary_color }}>
                B.S. in Chemical Engineering
              </span>{" "}
              and a{" "}
              <span style={{ color: primary_color }}>
                M.S. in Computer Science
              </span>{" "}
              with a specialization in machine learning
            </h5>
            {/* <Timeline className="col-md-6 float-md-end mb-3 ms-md-3"style={{alignContent:'left'}}> */}
            <Timeline position="alternate">
              {timeline_code}
              {timeline_today}
            </Timeline>
          </div>
        </CRow>
      </CContainer>
    </div>
  );
};

export default About;
