import React from "react";
// import { CContainer } from "@coreui/react"
import { Row } from "react-bootstrap";
import { Container } from "react-bootstrap";

const Contact = () => {
  return (
    <div style={{ margin: "0 auto" }}>
      <Container style={{ padding: 32 }}>
        <Row style={{ paddingTop: 32 }}>
          <h3>
            Please reach me at my{" "}
            <a href="https://linkedin.com/in/brent-marcoux">LinkedIn</a>!
          </h3>
        </Row>
      </Container>
    </div>
  );
};

export default Contact;
