import { CContainer, CRow, CCol } from "@coreui/react";
import React from "react";
import Typewriter from "typewriter-effect";
import developer from "./../../assets/developer.jpg";
import analytics from "./../../assets/analytics.jpg";

const Home = () => {
  const list_padding = 16;
  const primary_color = "#2979ff";
  const wid = window.innerWidth;
  let typewriter_size = "40px";
  if (wid < 450) {
    typewriter_size = "24px";
  }
  return (
    <div style={{ margin: "0 auto", alignContent: "center" }}>
      <CContainer style={{ padding: 24, maxWidth: 1400 }}>
        {/* <CCard> */}
        <CRow>
          <CCol style={{ margin: "auto", textAlign: "left" }}>
            <h1 style={{ paddingBottom: 15 }} className="heading">
              Hello! <br></br>My name is <br></br>
              <strong style={{ color: primary_color }}>Brent Marcoux</strong>
            </h1>
            <div style={{ fontSize: typewriter_size, color: primary_color }}>
              {/* <div style={{fontSize:'1.5em', color:primary_color}}> */}
              <Typewriter
                options={{
                  strings: [
                    "Chemical Engineer",
                    "Data Scientist",
                    "Software Developer",
                    "Lifelong Learner",
                  ],
                  autoStart: true,
                  loop: true,
                  deleteSpeed: 50,
                }}
              />
            </div>
          </CCol>
          <CCol>
            <img
              style={{ maxWidth: "70vw", maxHeight: "400px" }}
              src={developer}
              alt="Image1"
            />
          </CCol>
        </CRow>
        <br></br>
        <CRow>
          <CCol style={{ margin: "auto" }}>
            <h4 style={{ textAlign: "left", paddingTop: 64 }}>
              Let me introduce myself:
            </h4>
            <h5 style={{ marginTop: 16, textAlign: "left" }}>
              <ul style={{ listStyleType: "none" }}>
                <li style={{ marginBottom: list_padding }}>
                  Passionate <span style={{ color: "inherit" }}>developer</span>{" "}
                  and{" "}
                  <span style={{ color: "inherit" }}>
                    optimization engineer
                  </span>
                </li>
                <li style={{ marginBottom: list_padding }}>
                  Educated and experienced in{" "}
                  <b style={{ color: primary_color }}>
                    Chemical Engineering, B.S.
                  </b>{" "}
                  and{" "}
                  <b style={{ color: primary_color }}>Computer Science, M.S.</b>
                </li>
                <li style={{ marginBottom: list_padding }}>
                  Interested in{" "}
                  <i style={{ color: primary_color }}>
                    modern software development
                  </i>
                  , <i style={{ color: primary_color }}>quantitative trading</i>
                  , and{" "}
                  <i style={{ color: primary_color }}>
                    closed-loop intelligence (artificial intelligence)
                  </i>
                </li>
              </ul>
            </h5>
          </CCol>
          <CCol style={{ margin: "auto" }}>
            <img
              style={{ maxWidth: "70vw", maxHeight: "300px" }}
              src={analytics}
              alt="Image2"
            />
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default Home;
