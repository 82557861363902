import "./App.css";
import React from "react";
// import './style.css'
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import WorkExperience from "./components/WorkExperience/WorkExperience";
import Resume from "./components/Resume/Resume";
import Contact from "./components/Contact/Contact";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

import "@coreui/coreui/dist/css/coreui.min.css";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/workexperience" element={<WorkExperience />} />
          <Route path="/resume" element={<Resume />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
