import React from "react";
import { Container } from "react-bootstrap";

const WorkExperience = () => {
  const wid = window.innerWidth;
  var industry_size = 24;
  var other_size = 18;
  var box_width = 200;
  var box_height = 100;
  const primary_color = "#2979ff";

  if (wid < 450) {
    industry_size = "16px";
    other_size = "12px";
    box_width = 125;
    box_height = 75;
  }

  const industries = [
    "Software Development",
    "Healthcare",
    "Cybersecurity",
    "Energy",
  ];

  const skills = [
    "Full Stack Engineering",
    "MLOps",
    "Data Science",
    "Network Architecture",
    "Optimization - Metaheuristics",
    "Control Algorithms and Control Scheme Design",
  ];

  const tools = [
    "Azure DevOps",
    "Model Predictive Control",
    "Regulatory Control",
    "Python",
    "React",
    "NodeJS",
    "Kubernetes",
    "RabbitMQ",
    "Terraform",
    "Github Actions",
    "PostgreSQL",
    "Supervised ML - Tensorflow, etc.",
    "Reinforcement Learning - DRL",
    "AI - Genetic Algorithms",
  ];
  const industryItems = industries.map((industry) => {
    return (
      <div
        key={industry}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontWeight: "bold",
          color: "darkslategray",
          fontSize: industry_size,
          padding: 8,
          width: box_width,
          height: box_height,
          border: "3px",
          borderStyle: "solid",
          borderColor: primary_color,
        }}
      >
        {industry}
      </div>
    );
  });

  const techItems = tools.map((tech) => {
    return (
      <div
        key={tech}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontWeight: "bold",
          color: "darkslategray",
          fontSize: other_size,
          padding: 8,
          width: box_width,
          height: box_height,
          border: "3px",
          borderStyle: "solid",
          borderColor: primary_color,
        }}
      >
        {tech}
      </div>
    );
  });

  const experienceItems = skills.map((ex) => {
    return (
      <div
        key={ex}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontWeight: "bold",
          color: "darkslategray",
          fontSize: other_size,
          padding: 8,
          width: box_width,
          height: box_height,
          border: "3px",
          borderStyle: "solid",
          borderColor: primary_color,
        }}
      >
        {ex}
      </div>
    );
  });

  return (
    <div>
      <Container style={{ padding: 24 }}>
        {/* <h1 style={{ color: primary_color }}>COMING SOON!</h1> */}
        {/* <h1>Experiences</h1> */}
        <h2 style={{ color: primary_color, textAlign: "left" }}>Industries</h2>
        <ul
          style={{
            display: "flex",
            // justifyContent: "space-between",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-evenly",
            rowGap: 8,
          }}
        >
          {industryItems}
        </ul>
        <h2 style={{ textAlign: "left", color: primary_color }}>Skills</h2>
        <ul
          style={{
            display: "flex",
            // justifyContent: "space-between",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-evenly",
            rowGap: 8,
          }}
        >
          {experienceItems}
        </ul>
        <h2 style={{ textAlign: "left", color: primary_color }}>Tools</h2>
        <ul
          style={{
            display: "flex",
            // justifyContent: "space-between",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-evenly",
            rowGap: 8,
          }}
        >
          {techItems}
        </ul>
      </Container>
    </div>
  );
};

export default WorkExperience;
