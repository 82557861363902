import React from "react";
import { CFooter } from "@coreui/react";
import { FaLinkedinIn } from "react-icons/fa";
import { AiFillGithub } from "react-icons/ai";
import { Col } from "react-bootstrap";

const Footer = () => {
  const date = new Date();
  let year = date.getFullYear();

  return (
    // <CFooter style={vars} position="sticky">
    <CFooter style={{ bottom: 0, marginTop: "auto" }}>
      <Col>
        <span>Copyright &copy; {year} Brent Marcoux</span>
      </Col>
      <Col>
        <ul
          style={{
            listStyleType: "none",
            textAlign: "center",
            margin: "0 auto",
          }}
        >
          <li style={{ display: "inline-block", paddingRight: 8 }}>
            <a
              href="https://linkedin.com/in/brent-marcoux"
              style={{ color: "black" }}
            >
              <FaLinkedinIn />
            </a>
          </li>
          <li style={{ display: "inline-block" }}>
            <a href="https://github.com/bmarcoux3" style={{ color: "black" }}>
              <AiFillGithub />
            </a>
          </li>
        </ul>
      </Col>
    </CFooter>
  );
};

export default Footer;
